function runSurveyPreviews(content) {
    const surveys = content.querySelectorAll("[data-survey-preview]");

    if (surveys.length > 0) {
        import("./survey-preview").then(sjs => {
            surveys.forEach(el => {
                new sjs.SurveyPreviewer(el);
            });
        });
    }
}

function initializeSurveyCreator(content) {
    const surveys = content.querySelectorAll("[data-survey-editor]");

    if (surveys.length > 0) {
        import("./survey-editor").then(sjs => {
            surveys.forEach(el => {
                new sjs.SurveyEditor(el);
            });
        });
    }
}

function displaySurveyResponses(content) {
    const surveys = content.querySelectorAll("[data-survey-response]");

    if (surveys.length > 0) {
        import("./survey-response").then(sjs => {
            surveys.forEach(el => {
                new sjs.SurveyResponseViewer(el);
            });
        });
    }
}

function initializeSurveyResponseEditors(content) {
    const surveys = content.querySelectorAll("[data-survey-response-editor]");

    if (surveys.length > 0) {
        import("./survey-response-editor").then(sjs => {
            surveys.forEach(el => {
                new sjs.SurveyResponseEditor(el);
            });
        });
    }
}

window.addEventListener("htmx:load", e => {
    runSurveyPreviews(e.target);
    initializeSurveyCreator(e.target);
    displaySurveyResponses(e.target);
    initializeSurveyResponseEditors(e.target);
});
